import {
    LeftCircleOutlined,
    LockFilled,
    QuestionCircleOutlined,
    RightCircleOutlined,
    UnlockFilled,
    UserOutlined,
} from "@ant-design/icons";
import { environ } from "@ihr-radioedit/sdk-utils";
import { Menu } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import { useAuthContext } from "../../contexts/auth";

export const BottomMenu = ({ isCollapsed, updateCollapsed }: { isCollapsed: boolean; updateCollapsed: () => void }) => {
    const { user } = useAuthContext();
    const authHost = environ.dep("auth");
    const onHelpPage = useRouteMatch("/help");

    return (
        <Menu
            theme="dark"
            mode="inline"
            inlineCollapsed={isCollapsed}
            selectedKeys={[onHelpPage?.path.replace("/", "") || ""]}
        >
            {user ? (
                <Menu.Item className="menu-item">
                    <UserOutlined />
                    <span>{user.name}</span>
                </Menu.Item>
            ) : null}
            <Menu.Item className="menu-item">
                <a href={`http://${authHost}/${user ? "sign_out" : "_oauth/login"}`}>
                    {user ? <LockFilled /> : <UnlockFilled />}
                    <span>{user ? "Logout" : "Login"}</span>
                </a>
            </Menu.Item>
            <Menu.Item className="menu-item" key="help">
                <Link to="/help">
                    <QuestionCircleOutlined />
                    <span>Help</span>
                </Link>
            </Menu.Item>
            <Menu.Item className="menu-item">
                <div onClick={updateCollapsed} className="clickable">
                    {isCollapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                    <span>Collapse</span>
                </div>
            </Menu.Item>
        </Menu>
    );
};
